import React from 'react';
import "./services.scss";
import ServiceItem from "../../SingleFeatures/service_item";
import SectionTitle from "../section_title";
import serviceIcon_01 from "../../images/icon_1.png";
import serviceIcon_02 from "../../images/icon_2.png";
import serviceIcon_03 from "../../images/icon_3.png";

export default function Index() {
    return (
        <section className="deneb_service service_v1">
            <div className="shape_v2">
                <img src="assets/images/shape/shape_7.png" className="shape_7" alt="" />
            </div>
            <div className="container">
                <SectionTitle
                    title="Our Service"
                    description="We Offer a Wide
                    Variety of IT Services"
                />
                <div className="row">
                    <ServiceItem
                        icon={serviceIcon_03}
                        title="Web Development"
                        url="#"
                        description="We carry more than just good coding skills. Our experience makes us stand out from other web development."
                    />
                    <ServiceItem 
                      icon={serviceIcon_01}
                      title="Mobile Development"
                      url="#"
                      description="Create complex enterprise software, ensure reliable software integration, modernise your legacy system."
                    />
                    <ServiceItem
                        icon={serviceIcon_02}
                        title="UI/UX Design"
                        url="#"
                        description="Build the product you need on time with an experienced team that uses a clear and effective design process."
                    />
                </div>
                <div className="row">
                    <ServiceItem
                        icon={serviceIcon_03}
                        title="QA & Testing"
                        url="#"
                        description="Turn to our experts to perform comprehensive, multi-stage testing and auditing of your software."
                    />
                    <ServiceItem 
                      icon={serviceIcon_01}
                      title="IT Counsultancy"
                      url="#"
                      description="Trust our top minds to eliminate workflow pain points, implement new tech, and consolidate app portfolios."
                    />
                    <ServiceItem
                        icon={serviceIcon_02}
                        title="Dedicated Team"
                        url="#"
                        description="Over the past decade, our customers succeeded by leveraging Intellectsoft’s process of building, motivating."
                    />
                </div>
            </div>
        </section>                 
    )
}
