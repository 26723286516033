import React from 'react';
import aboutImg from "../../images/about.png";
import awardImg from "../../images/award.png";
import signImg from "../../images/sign_2.png";

export default function HireUs() {
    return (
        <section className="deneb_about about_v2">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <div className="deneb_img_box">
                            <img src={aboutImg} className="img-fluid" alt="about"/>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="deneb_content_box">
                            <h2>Your Partner for Software Innovation</h2>
                            <p>We’re not just a team of developers; we’re Riseupian – a tight-knit community of like-minded and passionate professionals in our field. We turn ideas into algorithms. Concepts into code. And, we’re able to help each other do a better job each day thanks to the common principles that drive us.
</p>
                            <p>
                            We can help to maintain and modernize your IT infrastructure and solve various infrastructure-specific issues a business may face.
                            </p>
                        </div>
                        {/* <div className="award_box">
                            <div className="award_img">
                                <img src={awardImg} alt="award"/>
                            </div>
                            <div className="award_info">
                                <img src={signImg} className="img-fluid" alt="signin"/>
                                <h4>Deneb Team</h4>
                                <h5>Best Award 2020</h5>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    )
}
