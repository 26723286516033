import React from 'react';
import skillImg from "../../images/skill.png";

export default function Skills() {
    return (
        <section className="deneb_skill">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="section_title">
                            <h2>Let Know Our Expert Skills</h2>
                            <p>Three elements are needed to manage innovation for business success: define goal, measure results and deliver time. Without clear objectives, your project will fail. In addition, your success depends on the correct mix of people, processes and products.</p>
                        </div>
                        <div className="skill_wrapper">
                            <div className="single_bar">
                                <div className="progress_title">
                                    <h5>Web Development<span>90%</span></h5>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar wow slideInLeft" style={{width: "90%"}}><div className="dot"></div></div>
                                </div>
                            </div>
                            <div className="single_bar">
                                <div className="progress_title">
                                    <h5>Mobile Application Development<span>95%</span></h5>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar wow slideInLeft" style={{ width: "95%" }}><div className="dot"></div></div>
                                </div>
                            </div>
                            <div className="single_bar">
                                <div className="progress_title">
                                    <h5>Custom Software Development<span>90%</span></h5>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar wow slideInLeft" style={{ width: "90%" }}><div className="dot"></div></div>
                                </div>
                            </div>
                            <div className="single_bar">
                                <div className="progress_title">
                                    <h5>Graphics Design<span>85%</span></h5>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar wow slideInLeft" style={{ width: "85%" }}><div className="dot"></div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="deneb_img_box">
                            <img src={skillImg} className="img-fluid" alt="skill" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
