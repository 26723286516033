import React from 'react';
import CallToAction from "../call_to_action";
import footerImg from "../../images/footer_bg.png";
import logoImg from "../../images/riseuplogo.png";
import {
    FaFacebookF,
    FaLinkedinIn,
    FaInstagram,
    FaPhoneAlt,
    FaEnvelope,
    FaMapMarkerAlt
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "./footer.scss";

export default function Index() {
    const scrollToTop = (e)=> {
        window.scrollTo({
            top: 0
        });
    }
    return (
	  <>
        <CallToAction/>
        <footer className="deneb_footer">
                <div className="widget_wrapper" style={{ backgroundImage: `url(${footerImg})`}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="widget widegt_about">
                                <div className="widget_title">
                                    <Link to="/" onClick={scrollToTop}><img height="150" width="150" src={logoImg} className="img-fluid" alt="logo" /></Link>
								</div>
                                    <p>Give us a call or drop by anytime, we endeavour to answer all enquiries within 24 hours on business days. We will be happy to answer your questions.</p>
                                    <ul className="social">
                                        <li><a target="_blank" href="https://www.facebook.com/riseuptechnology"><i><FaFacebookF/></i></a></li>
                                        <li><a target="_blank" href="https://www.linkedin.com/company/riseup-technology-in"><i><FaLinkedinIn/></i></a></li>
                                        <li><a target="_blank" href="https://www.instagram.com/riseup_technology"><i><FaInstagram/></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="widget widget_link">
                                    <div className="widget_title">
                                        <h4>Links</h4>
                                    </div>
                                    <ul>
                                        <li><Link to="/about" onClick={scrollToTop}>About Us</Link></li>
                                        <li><Link to="/services" onClick={scrollToTop}>Services</Link></li>
                                        <li><Link to="/portfolios" onClick={scrollToTop}>Portfolios</Link></li>
                                        <li><Link to="/our_team" onClick={scrollToTop}>Our Teams</Link></li>
                                        <li><Link to="/contact" onClick={scrollToTop}>Contact Us</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="widget widget_contact">
                                    <div className="widget_title">
                                        <h4>Contact Us</h4>
                                    </div>
                                    <div className="contact_info">
                                        <div className="single_info">
                                            <div className="icon">
                                                <i><FaPhoneAlt/></i>
                                            </div>
                                            <div className="info">
                                                <p><a href="tel:+917600747503">+91 76007-47503</a></p>
                                                <p><a href="tel:+1 2498787233">+1 249-878-7233</a></p>
                                            </div>
                                        </div>
                                        <div className="single_info">
                                            <div className="icon">
                                                <i><FaEnvelope/></i>
                                            </div>
                                            <div className="info">
                                                <p><a href="mailto:info@riseuptechnology.com">info@riseuptechnology.com</a></p>
                                                <p><a href="mailto:support@riseuptechnology.com">support@riseuptechnology.com</a></p>
                                            </div>
                                        </div>
                                        <div className="single_info">
                                            <div className="icon">
                                                <i><FaMapMarkerAlt/></i>
                                            </div>
                                            <div className="info">
                                                <p>608, Blue Point, Sarthana Jakatnaka<span> Surat, Gujarat, India 395013.</span></p>
                                            </div>
                                            
                                        </div>
                                        <div className="single_info">
                                            <div className="icon">
                                                <i><FaMapMarkerAlt/></i>
                                            </div>
                                            <div className="info">
                                                <p>35 Widdicombe Hill, Toronto, ON, CA, M9R 1B2.</p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright_area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="copyright_text">
                                    <p>Copyright &copy; {(new Date().getFullYear())} <span>Riseup Technology</span>. All rights reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
		</footer>
     </>
            
    )
}
