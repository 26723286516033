import React from 'react'
import { Tab, Nav, Row, Col } from "react-bootstrap";
import icon_11 from "../../images/icon_11.png";
import icon_12 from "../../images/icon_12.png";
import icon_13 from "../../images/icon_13.png";
import icon_14 from "../../images/icon_14.png";
import icon_15 from "../../images/icon_15.png";
import icon_16 from "../../images/icon_16.png";
import serviceImg_01 from "../../images/services/service1.png";
import serviceImg_02 from "../../images/services/02.jpg";
import serviceImg_03 from "../../images/services/03.jpg";
import serviceImg_04 from "../../images/services/04.jpg";
import serviceImg_05 from "../../images/services/service5.jpg";
import serviceImg_06 from "../../images/services/06.jpg";
export default function ServicesTabs() {
    return (
        <section className="service_wrapper section_padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <div className="service_tab_wrap">
                            <div className="service_tab_nav">
                                <Nav className="nav nav-tabs">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">
                                            <img src={icon_11} />
                                            <span>Web Development</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">
                                            <img src={icon_12} />
                                            <span>Application Development</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">
                                            <img src={icon_13} />
                                            <span>Custom Software Development</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="fourth">
                                            <img src={icon_14} />
                                            <span>Ecommerce Development</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="fifth">
                                            <img src={icon_15} />
                                                <span>UI/UX Design</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="six">
                                            <img src={icon_16} />
                                            <span>API Service Development</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className="tab-content">
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <div className="row align-items-center">
                                            <div className="col-lg-6">
                                                <div className="deneb_content_box">
                                                    <h2>Web Development</h2>
                                                    <p>Riseup offers a wide range of tailored web development services delivered by intelligent, experienced specialists to streamline a business workflow, nail a startup, or build a best-seller product that’s perfectly optimized in all the right places.</p>
                                                    <p>We provide market-leading solutions powered by flexible collaboration, in-depth expertise, and relevant technology wielded by well-tried professionals.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="deneb_img_box">
                                                    <img src={serviceImg_01} className="img-fluid" alt="services"/>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <div className="row align-items-center">
                                            <div className="col-lg-6">
                                                <div className="deneb_content_box">
                                                    <h2>Application Development</h2>
                                                    <p>Our vast expertise covers full-pledged software development services for mobile platforms: from business analysis and prototyping to quality assurance and further deployment of mobile applications.</p>
                                                    <p>Riseup Technology provides custom mobile application development services of any complexity using a technical approach to clients’ business needs. We cover all applications, from consumer-oriented to mobile enterprise apps designed to serve B2B, B2C, or B2E sectors.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="deneb_img_box">
                                                    <img src={serviceImg_02} className="img-fluid" alt="services" />
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        <div className="row align-items-center">
                                            <div className="col-lg-6">
                                                <div className="deneb_content_box">
                                                    <h2>Custom Software Development</h2>
                                                    <p>Riseup Technology is a leading enterprise web development company in delivering a wide range of enterprise solutions that meet every type of industry-specific needs.</p>
                                                    <p>We are expert in developing enterprise-grade solutions, web portals, eCommerce solutions, B2B & B2C applications, ERP & CRM software apps and secure transactions.</p>
                                                    <p>Updated with latest technology, our web development services’ outputs are creative, intuitively interactive and visually appealing.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="deneb_img_box">
                                                    <img src={serviceImg_03} className="img-fluid" alt="services" />
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="fourth">
                                        <div className="row align-items-center">
                                            <div className="col-lg-6">
                                                <div className="deneb_content_box">
                                                    <h2>Ecommerce Development</h2>
                                                    <p>We bring you unlimited stores developed from scratch and leveraged from popular platforms like WooCommerce or Shopify with unique and creative ideas, for eventual online shopping experience.</p>
                                                    <p>Our brilliancy in serving full-fledged online web stores for all types of business, either be it a startup or large enterprise, have made us a leading ecommerce web development company.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="deneb_img_box">
                                                    <img src={serviceImg_04} className="img-fluid" alt="services" />
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="fifth">
                                        <div className="row align-items-center">
                                            <div className="col-lg-6">
                                                <div className="deneb_content_box">
                                                    <h2>UI/UX Design</h2>
                                                    <p>Riseup offers a whole gamut of UI/UX design services to transform unremarkable mobile apps, websites, dashboards, and cyber-physical systems into tech solutions that delight users and bring tangible business results.</p>
                                                    <p>Our team of professional UI UX Developers craft each interface of the iOS and Android Apps and translate your business into a seamless mobile app user experience.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="deneb_img_box">
                                                    <img src={serviceImg_05} className="img-fluid" alt="services" />
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="six">
                                        <div className="row align-items-center">
                                            <div className="col-lg-6">
                                                <div className="deneb_content_box">
                                                    <h2>API Service Development</h2>
                                                    <p>SOAP, REST, ASMX and more types of services to integration and interaction of data with high security and high performance</p>
                                                    <p>Our API developers using C, C++, Java, Python, and PHP to create powerful, secure, and easily maintainable APIs. Experienced in delivering the API integration services to provide the access to well-designed backend solutions at a competitive price. </p>
                                                    <p>Our API Developers build Cloud APIs that can help your clients to interact with your cloud services and also enhances their experience. </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="deneb_img_box">
                                                    <img src={serviceImg_06} className="img-fluid" alt="services" />
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </div>
                     </Tab.Container>
                    </div>
                </div>
            </div>
        </section>
    )
}
