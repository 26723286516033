import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

export default function blog_item(props) {
  const {title,name, profile_picture, description} = props;

  const scrollToTop = (e) => {
    window.scrollTo({top: 0});
  }
  return (
    <div className='col-md-3'>
    <Card className='card-shadow' style={{width: '100%', marginTop:"20px"}}>
      <Card.Img variant="top" style={{"border-radius": "50%", "padding":"15px" }} src={profile_picture}/>
      <Card.Body>
        <Card.Title style={{"text-align":"center"}}>{name}</Card.Title>
        <Card.Subtitle style={{"text-align":"center"}}>{title}</Card.Subtitle>
        <Card.Text>
         {description}
        </Card.Text>

      </Card.Body>
    </Card></div>
  )
}
