import React from 'react';
import aboutImg from "../../images/about.png";
import { Link } from "react-router-dom";
import "./about.scss";

export default function Index() {
    const scrollToTop = (e) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    return (
        <section className="deneb_about about_v1">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <div className="deneb_img_box wow fadeInLeft">
                            <img src={aboutImg} className="img-fluid" alt="about"/>
						</div>
                    </div>
                    <div className="col-lg-7">
                        <div className="deneb_content_box wow fadeInRight">
                            <h2>Your Partner for Software Innovation</h2>
                            <p>Riseup is the partner of choice for many of the world’s leading enterprises, SMEs and technology challengers. We help businesses elevate their value through custom software development, product design, QA and consultancy services.</p>
                            <p>We can help to maintain and modernize your IT infrastructure and solve various infrastructure-specific issues a business may face.</p>
                            <Link to="/about" onClick={scrollToTop} className="deneb_btn">Read More</Link>
                        </div>
                    </div>
                </div>
            </div>
		</section>
    )
}
