import React from 'react';
import shape_img_12 from "../../images/shape/shape_12.png";
import shape_img_13 from "../../images/shape/shape_13.png";
import people_img_01 from "../../images/1.png";
import people_img_02 from "../../images/2.png";
import people_img_03 from "../../images/3.png";
import people_img_04 from "../../images/4.png";
import people_img_05 from "../../images/5.png";
import people_img_06 from "../../images/6.png";
import people_img_07 from "../../images/7.png";
import testimonial_img_01 from "../../images/testimonial_1.jpg";
import testimonial_img_02 from "../../images/testimonial_15.jpg";
import testimonial_img_03 from "../../images/testimonial_11.jpg";
import testimonial_img_04 from "../../images/testimonial_16.jpg";
import testimonial_img_05 from "../../images/testimonial_14.jpg";
import testimonial_img_06 from "../../images/testimonial_17.jpg";
import testimonial_img_07 from "../../images/testimonial_18.jpg";
import testimonial_img_08 from "../../images/testimonial_13.jpg";
import "slick-carousel/slick/slick.css";
import "./testimonials.scss";
import SectionTitle from "../section_title";
import TestimonialItem from "../../SingleFeatures/testimonial_item";
import Slider from "react-slick";

export default function Index() {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <section className="deneb_testimonial testimonial_v1">
            <div className="shape_v5">
                <img src={shape_img_12} className="shape_12" alt="shape" />
                <img src={shape_img_13} className="shape_13" alt="shape" />
            </div>
            <div className="container">
                <SectionTitle
                    title="What People Say"
                    description="We are Trusted
                    15+ Countries Worldwide"
                />
                <div className="row1">
                    <div className="single_img">
                        <img src={people_img_01} className="img-fluid" alt="people" />
                    </div>
                    <div className="single_img sm_img">
                        <img src={people_img_02} className="img-fluid" alt="people" />
                    </div>
                    <div className="single_img sm_img">
                        <img src={people_img_03} className="img-fluid" alt="prople" />
                    </div>
                </div>
                <div className="row2">
                    <div className="single_img">
                        <img src={people_img_04} className="img-fluid" alt="prople" />
                    </div>
                    <div className="single_img sm_img">
                        <img src={people_img_05} className="img-fluid" alt="prople" />
                    </div>
                    <div className="single_img sm_img">
                        <img src={people_img_06} className="img-fluid" alt="people" />
                    </div>
                    <div className="single_img">
                        <img src={people_img_07} className="img-fluid" alt="people" />
                    </div>
                </div>
                <div className="testimonial_slide carousel-style">
                    <Slider {...settings}> 
                        <TestimonialItem
                            img={testimonial_img_01}
                            name="Michel Cole"
                            designation="Manager of Cole"
                            text="Outstanding developer team! I was about to give up the task because several others could not solve it, but he executed it perfectly, communicated every step in detail and with several options given, and on top was doing all of this in a very reasonable time and budget! I Will for sure with all again!"

                        />
                          <TestimonialItem
                            img={testimonial_img_02}
                            name="Branding Design"
                            designation="Emila Johnson"
                            text="Riseup did great work. I didn’t expect that the logo and packaging will be so nice and lovely. The colours matched perfectly. I didn’t know what exactly I wanted but he helped me understand it. Even after the project was completed he kept in contact to help. No doubt that Riseup knows his work and would highly recommend him for his great creativity and professionalism. Thank you Riseup Technology team for doing a wonderful design job."
                        />
                        <TestimonialItem
                            img={testimonial_img_03}
                            name="Chirag "
                            designation="CEO of Buskol.com"
                            text="Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Patience. Infinite patience. No shortcuts. Even if the client is being careless. The best part...always solving problems with great original ideas!."

                        />
                         <TestimonialItem
                            img={testimonial_img_04}
                            name="Lisa M."
                            designation="Kiss Nature"
                            text="Patience. Infinite patience. No shortcuts. Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Even if the client is being careless. The best part...always solving problems with great original ideas!."
                        />
                         <TestimonialItem
                            img={testimonial_img_05}
                            name="Dr.Ghanshyam M."
                            designation="Founder of Jeevandip Hospital"
                            text="Riseup Technology developers are super helpful and knowledgeable! He was very patient and really saw to all of my needs! I will be using him again in the future and recommend anyone to pick from Riseup Technology developers. He is all around very nice, pleasant, and professional."
                        />
                         <TestimonialItem
                            img={testimonial_img_06}
                            name="Hiren M."
                            designation="Owner at Manki Fashion"
                            text="We worked very closely with Riseup Technology for my fashion design company. The team understood the requirements perfectly and delivered high-quality work. I will always work with him and if you're reading this you should too. Can't wait for the next project with him."
                        />
                         <TestimonialItem
                            img={testimonial_img_07}
                            name="Ravi P."
                            designation="Uma PolyPlast"
                            text="This was an excellent experience. Riseup Technology was very fast and efficient. Did the work for a good price and was happy and polite through the process. Would be very happy to recommend him and work with him again in the future. Thank you!"
                        />
                         <TestimonialItem
                            img={testimonial_img_08}
                            name=""
                            designation="Kalakunj Swaminarayan Temple"
                            text="It was a pleasure to work with Riseup Technology. Great quality of work and fast turnaround. Communication was smooth. The result was above my expectations and he even went the extra mile with the quality of his work! Will work with him again!"
                        />
                       
                        {/* <TestimonialItem
                            img={testimonial_img_03}
                            name="Celesto Anderson"
                            designation="Graphic Designer"
                            text="Donec condimentum vehicula iaculis. Maecenas in aliquet neque.
                                Suspendisse viverra, ante eget pellentesque pulvinar, nunc nisi
                                molestie ligula, vitae convallis orci justo vitae sem. Integer vitae
                                imperdiet augue, sed accumsan diam. Etiam non quam commodo dolor
                                convallis cursus. Duis tempus dolor eget gravida fringilla. In
                                ultricies velit eget sem tempus egestas"

                        /> */}
                    </Slider>
                </div>
            </div>
        </section>

    )
}
