import React from 'react';

export default function MapLocation() {
    return (
        <section className="deneb_map">
            <div className="map_box">
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d3071.2910802067827!2d90.45905169331171!3d23.691532202989123!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1577214205224!5m2!1sen!2sbd"></iframe> */}
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.2548960430145!2d72.83613071531587!3d21.221738586537228!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04ee8662fbeb7%3A0x5c3350ca9b047b9a!2sRiseup%20Technology!5e0!3m2!1sen!2sca!4v1649698812091!5m2!1sen!2sca"></iframe>
            </div>
        </section>
    )
}
