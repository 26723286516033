import React from 'react';
import Layout from "../Layouts";
import PageHeader from "../Components//page_header";
import HireUs from "../Components/about/hire_us";
import ContactForm from "../Components/careers/contact_form";

export default function About() {
    return (
      <Layout>
      <PageHeader title="Career"/>
      <HireUs/>
      <ContactForm/>
   </Layout>
    )
}
