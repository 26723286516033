import React from 'react';
import SectionTitle from "../section_title";
import ProfileItem from "../../SingleFeatures/profile_item";
import data from "../../data/team_profiles.json";
import "./blogs.scss";

export default function Index() {
    const profiles = data.profiles
    return (
        <section className="deneb_blog blog_v1">
            <div className="container">
                <SectionTitle
                    title="Meet Our Riseupian"
                    description="Teamwork begins by building trust. And the only way to do that is to overcome our need for invulnerability."
                />
                <div className="row">
                    {profiles.map(profile => (
                        <ProfileItem key={profile.id}
                                     name={profile.name}
                                     title={profile.title}
                                     profile_picture={profile.profile_picture}
                                     description={profile.description}
                                     img={profile.blogImage}
                        />
                    ))}
                </div>
            </div>
        </section>

    )
}
