import React from 'react';
import shapeImg from "../../images/shape/shape_11.png";
import icon_04 from "../../images/icon_4.png";
import SectionTitle from "../section_title";
import "./pricing.scss";
import PricingItem from "../../SingleFeatures/pricing_item";

export default function Index() {

    return (
        <section className="deneb_pricing pricing_v1">
            <div className="shape_v4">
                <img src={shapeImg} className="shape_11" alt="shape"/>
            </div>
            <div className="container">
                <SectionTitle
                    title="Flexible Pricing Plans"
                    description="We help businesses elevate their value through custom software development,
                    product design, QA and consultancy services."
                />
                <div className="row">
                    <PricingItem
                      title="Hourly"
                      price="129.99"
                      plan_type="month"
                      url="contact"
                      icon={icon_04}
                    >
                        <ul>
                            <li>Web Consulting</li>
                            <li>As Needed</li>
                            <li>Flexible Working Hours</li>
                            <li>Weekly Billing</li>
                            <li>Daily Reporting</li>
                        </ul>
                    </PricingItem>
                    <PricingItem
                     title="Monthly"
                      price=""
                      plan_type="month"
                      url="#"
                      icon={icon_04}
                    >
                    <ul>
                       
                        <li>160 Hours</li>
                        <li>Flexible Working Hours**</li>
                        <li>Monthly Billing</li>
                        <li>Daily Reporting</li>
                        <li>Money Back Guarantee***</li>
                       {/* <li><del>Daily Reporting</del></li> */}
                    </ul>
                    </PricingItem>
                    <PricingItem
                      title="Fixed Quote"
                      price="189.99"
                      plan_type="month"
                      url="#"
                      icon={icon_04}
                    >
                    <ul>
                        <li>Web Consulting</li>
                        <li>Hours as Needed</li>
                        <li>Flexible Working Hours**</li>
                        <li>Milestone Billing</li>
                        <li>Daily Reporting</li>
                    </ul>
                    </PricingItem>
                </div>
            </div>
        </section>

    )
}
