import React from 'react';
import "./portfolios.scss";
import shapeImg_01 from "../../images/shape/shape_10.png";
import {Tabs, Tab} from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import PortfolioItem from "../../SingleFeatures/portfolio_item";
import SectionTitle from "../section_title";
import data from "../../data/portfolio.json";

export default function Index(props) {
    const {scroller} = props;
    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    centerPadding: "30px"
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const ConditionalWrapper = ({condition, wrapper, children, elseWrapper}) =>
        condition ? wrapper(children) : elseWrapper ? elseWrapper(children) : children;
    // const updateSetting = (category) => {
    //     let a = settings;
    //     a.slidesToShow = data[category].entries.length > 3 ? 3 : data[category].entries.length;
    //     console.log(a);
    //     return settings
    // }

    return (
        <section className="deneb_project project_v1 section_padding">
            <div className="shape_v3">
                <img src={shapeImg_01} className="shape_10" alt="shape"/>
            </div>
            <div className="container">
                <SectionTitle
                    title="Our Latest Projects"
                    description="Software development outsourcing is just a tool to achieve business goals. But there is no way
                    to get worthwhile results without cooperation and trust between a client company."
                />
                <div className="row">
                    <div className="col-lg-12 carousel-style projects_slider_content">
                        <Tabs defaultActiveKey="development" id="portfolio-tabs">
                            {Object.keys(data).map(category => (
                                <Tab eventKey={category} title={data[category].title}
                                     className="project_btn active_btn">
                                    <div className="mt-5">

                                        <ConditionalWrapper
                                            condition={scroller}
                                            wrapper={children => <Slider {...settings}>{children}</Slider>}
                                            elseWrapper={children => <div className='row'>{children}</div>}>

                                            {data[category].entries.map(item => (
                                                <ConditionalWrapper
                                                    condition={!scroller}
                                                    wrapper={children => <div
                                                        className="col-lg-4 col-md-6">{children}</div>}>
                                                    <div>
                                                        <PortfolioItem
                                                            img={item.image}
                                                            title={item.title}
                                                            url={item.url}
                                                        />
                                                    </div>
                                                </ConditionalWrapper>


                                            ))}


                                        </ConditionalWrapper>

                                    </div>
                                </Tab>
                            ))}
                            {/*
                            
                            <Tab eventKey="apps" title="Logo Design" className="project_btn active_btn">
                                <div className="mt-5">
                                    <Slider {...settings}>
                                        <div>
                                            <PortfolioItem
                                                img={logo1}
                                                title="Enso Coffee Shop"
                                                tag=""
                                            />
                                        </div>
                                        <div>
                                            <PortfolioItem
                                                img={logo2}
                                                title="Riseup Ventures"
                                                tag=""
                                            />
                                        </div>
                                        <div>
                                            <PortfolioItem
                                                img={logo3}
                                                title="Eternal Cosultancy"
                                                tag=""
                                            />
                                        </div>
                                        <div>
                                            <PortfolioItem
                                                img={logo4}
                                                title="Aastha Fashion"
                                                tag=""
                                            />
                                        </div>
                                        <div>
                                            <PortfolioItem
                                                img={logo5}
                                                title="Bliss Rangazza"
                                                tag=""
                                            />
                                        </div>
                                        <div>
                                            <PortfolioItem
                                                img={logo6}
                                                title="Suhani Art Work"
                                                tag=""
                                            />
                                        </div>
                                        <div>
                                            <PortfolioItem
                                                img={logo7}
                                                title="Transcendence Technology"
                                                tag=""
                                            />
                                        </div>
                                        <div>
                                            <PortfolioItem
                                                img={logo8}
                                                title="Janki Fashion"
                                                tag=""
                                            />
                                        </div>
                                        <div>
                                            <PortfolioItem
                                                img={logo9}
                                                title="Delta TechnoLabs"
                                                tag=""
                                            />
                                        </div>
                                        <div>
                                            <PortfolioItem
                                                img={logo10}
                                                title="Mother Jewels"
                                                tag=""
                                            />
                                        </div>
                                        <div>
                                            <PortfolioItem
                                                img={logo11}
                                                title="Motisariya"
                                                tag=""
                                            />
                                        </div>
                                    </Slider>
                                </div>
                            </Tab>
                            <Tab eventKey="marketing" title="Marketing" className="project_btn active_btn">
                                <div className="mt-5">
                                    <Slider {...settings}>
                                        <div>
                                            <PortfolioItem
                                                img={portfolioImg_01}
                                                title="Video Editing"
                                                tag="Editing"
                                            />
                                        </div>
                                        <div>
                                            <PortfolioItem
                                                img={portfolioImg_02}
                                                title="Programming"
                                                tag="Coding"
                                            />
                                        </div>
                                        <div>
                                            <PortfolioItem
                                                img={portfolioImg_03}
                                                title="Traveling App"
                                                tag="Traveling"
                                            />
                                        </div>
                                    </Slider>
                                </div>
                            </Tab> */}
                        </Tabs>
                    </div>
                </div>
            </div>
        </section>
    );
}
