import React from 'react';
import serviceImg_01 from "../../images/flow1.png";
import serviceImg_02 from "../../images/flow2.png";
import serviceImg_03 from "../../images/flow3.png";
import serviceImg_04 from "../../images/flow4.png";
import serviceImg_05 from "../../images/flow5.png";
import serviceImg_06 from "../../images/flow6.png";
import serviceImg_07 from "../../images/flow7.png";
import icon_17 from "../../images/icon_17.png"
import icon_18 from "../../images/icon_18.png"
import icon_19 from "../../images/icon_19.png"

export default function WorkFlow() {

    return (
        <section className="service_feature">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section_title text-center">
                            <h2>Our Work Flow</h2>
                            <p>We follow a proprietary development process that has helped us produce intuitive and user-friendly software for businesses to harness.</p>
                        </div>
                    </div>
                </div>
                <div className="feature_wrapper">
                    <div className="single_feature">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="deneb_img_box">
                                    <img src={serviceImg_01} className="img-fluid" alt="service"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="deneb_content_box">
                                    <div className="icon">
                                        <img src={icon_17} alt="icon"/>
                                    </div>
                                    <div className="info">
                                        <h3>Requirement Gathering</h3>
                                        <p>We follow the first and foremost priority of gathering requirements, resources, and information to begin our project.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="single_feature">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="deneb_content_box">
                                    <div className="icon">
                                        <img src={icon_18} alt="icon" />
                                    </div>
                                    <div className="info">
                                        <h3>UI/UX Design</h3>
                                        <p>We create catchy and charming designs with the latest tools of designing to make it a best user-friendly experience.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="deneb_img_box">
                                    <img src={serviceImg_02} className="img-fluid" alt="service"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="single_feature">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="deneb_img_box">
                                    <img src={serviceImg_03} className="img-fluid" alt="service"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="deneb_content_box">
                                    <div className="icon">
                                        <img src={icon_19} alt="icon"/>
                                    </div>
                                    <div className="info">
                                        <h3>Prototype</h3>
                                        <p>After designing, you will get your prototype, which will be sent ahead for the development process for the product.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="single_feature">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="deneb_content_box">
                                    <div className="icon">
                                        <img src={icon_18} alt="icon" />
                                    </div>
                                    <div className="info">
                                        <h3>Development</h3>
                                        <p>Development of mobile application/web/blockchain started using latest tools and technologies with transparency.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="deneb_img_box">
                                    <img src={serviceImg_04} className="img-fluid" alt="service"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="single_feature">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="deneb_img_box">
                                    <img src={serviceImg_05} className="img-fluid" alt="service"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="deneb_content_box">
                                    <div className="icon">
                                        <img src={icon_19} alt="icon"/>
                                    </div>
                                    <div className="info">
                                        <h3>Quality Assurance</h3>
                                        <p>Riseup values quality and provides 100% bug free application with no compromisation in it.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="single_feature">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="deneb_content_box">
                                    <div className="icon">
                                        <img src={icon_18} alt="icon" />
                                    </div>
                                    <div className="info">
                                        <h3>Deployment</h3>
                                        <p>After trial and following all processes, your app is ready to launch on the App store or Play Store.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="deneb_img_box">
                                    <img src={serviceImg_06} className="img-fluid" alt="service"/>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="single_feature">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="deneb_img_box">
                                    <img src={serviceImg_07} className="img-fluid" alt="service"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="deneb_content_box">
                                    <div className="icon">
                                        <img src={icon_19} alt="icon"/>
                                    </div>
                                    <div className="info">
                                        <h3>Support & Maintenance</h3>
                                        <p>Our company offers you all support and the team is always ready to answer every query after deployment.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </section>
    )
}
